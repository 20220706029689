import IEnvironment, { EnvironmentsEnum } from '@environments/environment.interface';

const baseCmsUrl = 'https://dev-cmsapi.mohawkind.com/site/ac';

export const environment: IEnvironment = {
  version: '0.0.0',
  environment: EnvironmentsEnum.Development,
  production: false,
  port: 0,
  apiUrl: '',
  brxmBaseUrl: baseCmsUrl,
  cmsBaseUrl: `${baseCmsUrl}/resourceapi`
};
